import ReactGA from "react-ga4";
import EventsRedirect from "../components/EventsRedirect";

ReactGA.send({ hitType: "pageview", page: window.location.pathname+window.location.search});

function EventsPage() {
  return (<div className="App">
    <EventsRedirect/>
    <div className='wrapper'>
    </div>
  </div>);
}

export default EventsPage;
