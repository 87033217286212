import ReactGA from "react-ga4";
import DownloadRedirect from "../components/DownloadRedirect";

ReactGA.send({ hitType: "pageview", page: window.location.pathname+window.location.search});

function DownloadPage() {
  return (<div className="App">
    <DownloadRedirect/>
    <div className='wrapper'>
    </div>
  </div>);
}

export default DownloadPage;
