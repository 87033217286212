import DownloadPage from "./routes/DownloadPage";
import HomePage from "./routes/HomePage";
import EventsPage from "./routes/EventsPage";

import {
  ChakraProvider,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {/* <Route path="/upload*" element={<UploadPage/>} /> */}
          <Route path="/" element={<HomePage/>} />
          <Route path="/download" element={<DownloadPage/>} />
          <Route path="/events" element={<EventsPage/>} />
          <Route path="/event" element={<EventsPage/>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
