import React from "react";

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

function DetectAndServe() {
    let os = getMobileOperatingSystem();
    if (os.toLowerCase() === "android") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.hypedating.hype.prod&referrer=utm_source%3Dweb_download_redirect"; 
    } else if (os.toLowerCase() === "ios") {
        window.location.href = "https://apps.apple.com/app/apple-store/id1617002045?pt=123705559&ct=web_download_redirect&mt=8";
    } else if (os.toLowerCase() === "windows phone") {
        window.location.href = "https://www.justbetter.app";
    } else {
        window.location.href = "https://www.justbetter.app";
    }
}

class DownloadRedirect extends React.Component {
    render() {
      return DetectAndServe();
    }
}

export default DownloadRedirect;
