import React from "react";
import '../css/styles.css';
import '../css/bootstrap.min.css';
import '../css/queries.css';
import '../css/jquery.fancybox.css';
import '../css/animate.css'
// import ReactGA from "react-ga4";

// function handleClick(e) {
//   e.preventDefault();
//   ReactGA.event({
//     category: "SignUp",
//     action: "onclick",
//     label: "Introduction", // optional
//     value: 1, // optional, must be a number
//     nonInteraction: false, // optional, true/false
//     transport: "xhr", // optional, beacon/xhr/image
//   });
//   window.location.href = "https://forms.gle/6jqBUcfRn6nzPWG77";
// }

class Introduction extends React.Component {
  render() {
    return (
      <section className="about-us" id="getstarted">
        <div className="container">
          <div className="row">
            <div className="col-md-8">

              <h2 id="who">Who are we?</h2>
              <p>
                We're a Boston based community of people who want deeper connections in the existing dating scene. Our conversation app is for singles to share, connect, and meet is LIVE.
              </p>
              <p>
                • Better is for people who can hold a good conversation <br />
                • Better is the birthplace for slow-burn love <br />
                • Better is quality > quantity <br />
                • Better is a community that builds you up <br />
                • Better is inclusive <br />
                • Better is real life without filters <br />
                • Better is here to build a Better dating culture <br />
                • Better is a movement <br />
              </p>
              <h2 id="prompt-example">Who we are not</h2>
              <p>
                • Better isn't for everyone <br />
                • Better isn't 6 best moments from your past 5-10 years pieced together as a profile <br />
                • Better isn't a place to collect matches just to boost your ego. In fact, if you have thousands of matches, we're doing something seriously wrong <br />
                • Better isn't a place for f*ckbois, scammers, or people that stood up others no communication. Try Tinder <br />
              </p>
              <h2 id="why">Why do we do this?</h2>
              <p>
                Mingze Wang (Founder):<br /><br />
                When I was single, in my early 20s, the options to meet people were: work, bars, or dating apps.<br /><br />
                Work is out because I don't want to date coworkers. Bar is out because I hate loud noises and I'd rather talk and learn more about them. Plus, I like being sober.
                 I want to actually connect with people, rather than letting lust take the wheel.<br /><br />
                 That leaves the last option, online dating. I liked that it told me who is single around me, plus there's no "fear of approaching"
                  (which was my biggest setback). The issue was there were no dating apps optimized for relationships. Plenty for people who want to hook up.
                   While that was fun, but when you hit your late 20s, I want someone real who I can share a large pepperoni pizza with.
                    Someone whom I can celebrate the highs and lows. You may say, "There is - Hinge?" After a few dozens of boring conversations,
                     ghosted messages, and one too many "mehhhh" first Hinge dates, I began to think <b>"I am going to die alone."
                  With endless amount of people on my phone, I never felt more alone than ever.</b> <br /><br />

                  There's gotta be a Better way to meet people and get to know them! Meeting people week over week doing everyday things.
                   A place where I can share my stories, lifestyle, and passion with a potential partner.
                    <b>I feel different everyday, why am I bounded to a static profile?</b> We're changing that. Your profile will grow with you!!!<br /><br />

                I do this, so others like me don't have to go through the same thing. I do this, so others like me can find someone to <b>share their life with.</b>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Introduction;
