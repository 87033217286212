import Footer from '../components/Footer'
import Introduction from '../components/Introduction'
import SplashOverlay from '../components/SplashOverlay'
import SplashVideo from '../components/SplashVideo'
import ReactGA from "react-ga4";

ReactGA.send({ hitType: "pageview", page: window.location.pathname+window.location.search});

function HomePage() {
  return (<div className="App">

    <div className='wrapper'>
      <SplashVideo />
      <SplashOverlay />
      <Introduction />
      <Footer />
    </div>
  </div>);
}

export default HomePage;
