import React from "react";
import '../css/styles.css';
import '../css/bootstrap.min.css';
import '../css/queries.css';
import '../css/jquery.fancybox.css';
import '../css/animate.css'
import { HStack, Box } from '@chakra-ui/react'


class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div id="footer">
          <div className="container center">
            <div className="row">
              <div className="col-md-4 col-pb-sm
               text-center">
                <h3>Social Media</h3>
                <HStack spacing='24px' align='center'>
                  <Box p={5} shadow="md" flex="1" rounded="md">
                    <a href='https://www.tiktok.com/@justbetter.app'>
                      <img src="https://hype.my.canva.site/images/a3a1a83e4366b1fed8af78a8c5ed3b50.svg" alt="Black TikTok Logo" loading="lazy" style={{ width: "1.5em", height: "1.5em" }}></img>
                    </a>
                  </Box>
                  <Box p={5} shadow="md" flex="1" rounded="md" >
                    <a href='https://www.instagram.com/justbetter.app/'>
                      <img src="https://hype.my.canva.site/images/e42107178c16fb436fd351963fdbdd9e.svg" alt="Flat Fill Instagram Icon" loading="lazy" style={{ width: "1.5em", height: "1.5em" }}></img>
                    </a>
                  </Box>
                  <Box p={5} shadow="md" flex="1" rounded="md">
                    <a href='https://twitter.com/justbetterapp/'>
                      <img src="https://hype.my.canva.site/images/2f9d52a114a139b144c8166fbe6e20af.svg" alt="Flat Fill Twitter Icon" loading="lazy" style={{ width: "1.5em", height: "1.5em" }}></img>
                    </a>
                  </Box>
                  <Box p={5} shadow="md" flex="1" rounded="md">
                    <a href='https://www.facebook.com/justbetterapp/'>
                      <img src="https://hype.my.canva.site/images/7f16dd6723718a29878c36f153200e71.svg" alt="Flat Fill Facebook Icon" loading="lazy" style={{ width: "1.5em", height: "1.5em" }}></img>
                    </a>
                  </Box>
                </HStack>
              </div>
              <div className="col-md-4 col-pb-sm text-center">
                <a href="https://www.linkedin.com/company/hypedating/jobs/?viewAsMember=true">
                  <h3>Careers</h3>
                </a>
              </div>
              <div className="col-md-4 col-pb-sm text-center">
                <h3>Contact</h3>
                <a className="email" href = "mailto: contact@justbetter.app">contact@justbetter.app</a>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12 text-center">
              <p>
                <span className="block">
                  Videos are made from:{" "}
                  <a href="https://www.pexels.com/">
                    Pexel
                  </a>
                </span>
              </p>
            </div>
            <div className="col-md-12 text-center">
              <p>
                <span className="block">
                  © {new Date().getFullYear()} Hype Technologies CO., All Rights Reserved
                </span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
