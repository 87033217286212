import React from "react";
import '../css/styles.css';
import '../css/bootstrap.min.css';
import '../css/queries.css';
import '../css/jquery.fancybox.css';
import '../css/animate.css'
import {
    Box,
    Container,
    HStack,
    Image,
} from '@chakra-ui/react'

class Navigation extends React.Component {
    render() {
        return (
            <Box as="section" className="nav" pb={{ base: '12', md: '24' }}>
                <Box as="nav" bg="bg-surface">
                    <Container py={{ base: '4', lg: '5' }}>
                        <HStack spacing="10">
                            <Image className="animated fadeInUpDelayLogo logo" src="/betterLogo.png" alt="logo" />
                            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 mr-auto">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="/event">Event</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </HStack>
                    </Container>
                </Box>
            </Box>
        );
    }
}

export default Navigation;
