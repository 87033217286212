import React from "react";
import '../css/styles.css';
import '../css/bootstrap.min.css';
import '../css/queries.css';
import '../css/jquery.fancybox.css';
import '../css/animate.css'
import ReactGA from "react-ga4";
import Navigation from "./Navigation";
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
} from '@chakra-ui/react'

// function handleClick(e) {
//   e.preventDefault();
//   ReactGA.event({
//     category: "SignUp",
//     action: "onclick",
//     label: "Hero", // optional
//     value: 1, // optional, must be a number
//     nonInteraction: false, // optional, true/false
//     transport: "xhr", // optional, beacon/xhr/image
//   });
//   window.location.href = "https://forms.gle/6jqBUcfRn6nzPWG77";
// }

class SplashOverlay extends React.Component {
  render() {
    return (
      <><Navigation /><section className="hero" id="hero">
        <Flex className="container" p={{ base: 4, md: 14 }}
          w="100%"
          direction={{ base: 'column', md: 'column' }}>
          <div className="row">
            <div className="col-md-12 .col-md-offset-2 text-right"></div>
          </div>
          <div className="row">
            <div className="col-md-12 .col-md-offset-2 text-center appName">
              <h2 className="animated fadeInUpDelay marta">Dating made</h2>
              <br />
              <br />
              <br />
              <h1 className="animated fadeInUpDelay marta">Better.</h1>
              <br />
              <br />
              <br />
              <h2 className="animated fadeInUpDelay marta">With less perfection.</h2>
              <h2 className="animated fadeInUpDelay marta">More authenticity.</h2>
              <br />
            </div>
          </div>
          <Box flex='1' pt={{ base: '30', md: '24' }}>
            <Box as="nav" bg="bg-surface">
              <Container py={{ base: '4', lg: '5' }}>
                <HStack spacing="10" align="center">
                  <Center>
                    <a class="col text-center" href="https://apps.apple.com/app/apple-store/id1617002045?pt=123705559&ct=web_home_page&mt=8">
                      <img className="downloadBadge center"
                        src="apple-badge.png"
                        alt="Download on the App Store" />
                    </a>
                  </Center>
                  <Center>
                    <a href='https://play.google.com/store/apps/details?id=com.hypedating.hype.prod&referrer=utm_source%3Ddownload_redirect'>
                      <img
                        alt='Get it on Google Play'
                        src='google-badge.png'
                        className="downloadBadge center" />
                    </a>
                  </Center>
                </HStack>
              </Container>
            </Box>
          </Box>
        </Flex>
      </section></>
    );
  }
}

export default SplashOverlay;