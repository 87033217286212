import React from "react";

function redirect() {
    window.location.href = "https://partiful.com/e/85KZjtXj0dh5GAUKRxjI"; 
}

class EventsRedirect extends React.Component {
    render() {
      return redirect();
    }
}

export default EventsRedirect;
