import React from "react";
import '../css/styles.css';
import '../css/bootstrap.min.css';
import '../css/queries.css';
import '../css/jquery.fancybox.css';
import '../css/animate.css'


class SplashVideo extends React.Component {
  render() {
    return (
      <div></div>
    );
  }
}

export default SplashVideo;
